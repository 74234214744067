 <template>
  <div>
    <h2>{{options.header}}</h2>

    <label class="signUpInput new_password">
        <input v-model="password" type="password" required autofocus />
        <span class="placeholder">{{$Amplify.I18n.get('NEW PASSWORD')}}</span>
        <span class="passwordHelp">
          <strong>Requirements</strong>
          8+ Characters<br />
          1 Uppercase<br />
          1 Number<br />
          1 Special (!@#$%&amp;)
        </span>
      </label>

    <div
        v-for="attribute in options.user.challengeParam.requiredAttributes"
        :attribute="attribute"
        v-bind:key="attribute"
      >
      <div>{{attribute.charAt(0).toUpperCase() + attribute.slice(1)}}</div>
      <input
        v-model="requiredAttributes[attribute]"
        :placeholder="attribute.charAt(0).toUpperCase() + attribute.slice(1)"
      />
    </div>
    <div class="buttonWrap">
        <div class="error" v-if="error">{{ error }}</div>
        <button v-if="!sent" v-on:click="change" :disabled="!password">{{$Amplify.I18n.get('Update Password')}}</button>
    </div>
    <span>
        <a v-if="!sent" v-on:click="signIn">{{$Amplify.I18n.get('Back to Sign In')}}</a>
      </span>
  </div>
</template>
 <script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';
 export default {
  name: 'RequireNewPassword',
  props: ['requireNewPasswordConfig'],
  data () {
    return {
        user: '',
        error: '',
        password: '',
        logger: {},
        requiredAttributes: {},
        amplifyUI: AmplifyUI
    }
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Enter new password'),
        user: {
          challengeParam: {
            requiredAttributes: []
          }
        }
      }
      return Object.assign(defaults, this.requireNewPasswordConfig || {})
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
  },
  methods: {
    setError: function(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
    checkContact(user) {
      this.$Amplify.Auth.verifiedContact(user)
        .then(data => {
          AmplifyEventBus.$emit('localUser', this.user)
          AmplifyEventBus.$emit('authState', 'signedIn')
        })
        .catch((e) => this.setError(e))
    },
    change() {
      this.$Amplify.Auth.completeNewPassword(this.options.user, this.password, this.requiredAttributes)
        .then(user => {
            if (this.options.user.challengeName === 'SMS_MFA') {
                AmplifyEventBus.$emit('localUser', this.options.user)
                AmplifyEventBus.$emit('authState', 'confirmSignIn')
            } else if (this.options.user.challengeName === 'MFA_SETUP') {
              AmplifyEventBus.$emit('localUser', this.options.user)
              AmplifyEventBus.$emit('authState', 'setMfa')
            } else {
              this.checkContact(this.options.user);
            }
        })
        .catch(err => this.setError(err));
    },
    signIn: function() {
      AmplifyEventBus.$emit('authState', 'signedOut')
    },
  }
}
</script>
