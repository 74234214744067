<template>
  <div>
    <div class="authHeader">
      <h1>
        Basic Bible Studies from the Foundry
      </h1>
    </div>
    <Auth />
  </div>
</template>

<script>
// @ is an alias to /src
import Store from '@/store'
import HeaderComponent from '@/components/HeaderComponent.vue'
import Auth from '@/components/Auth.vue'

export default {
  name: 'Login',
  components: { HeaderComponent, Auth },
  computed: {
    // user() { return Store.state.user },
  },
}
</script>
