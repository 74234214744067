<template>
  <!-- <nav>
      <ul>
          <li class="complete" :class="[activeLesson === null ? 'active' : '']" @click="clearLessonContent()">Introduction</li>
          <li v-for="(lessonId, index) in lessonIds" :class="[getLessonStatus(lessonId, index), activeLesson === lessonId ? 'active' : '']" @click="getLesson(lessonId)" v-bind:key="index">Section {{index + 1}}</li>
      </ul>
  </nav> -->
  <nav>
      <flickity  class="flickity" ref="flickity" :options="flickityOptions" v-if="lessonIds">
          <span v-for="(lessonId, index) in lessonIds" :class="[getLessonStatus(lessonId, index), activeLesson === lessonId ? 'active' : '']" @click="getLesson(lessonId, index)" v-bind:key="index">Lesson {{index + 1}}</span>
      </flickity>
  </nav>
</template>

<script>
import Store from '@/store'
import Flickity from 'vue-flickity'

export default {
  name: 'SectionNav',
  components: { Flickity },
  props: [ 'lessonIds', 'currentLesson' ],
  data() {
    return {
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        percentPosition: false,
        freeScroll: true,
        cellAlign: 'center'
      }
    }
  },
  destroyed(){
    Store.commit('set_lesson_content', null)
    Store.commit('set_lesson_overlay_display', false)
  },
  mounted() {
    if(this.studyProgress && this.studyProgress.currentLesson){
      let index = this.lessonIds.indexOf(this.studyProgress.currentLesson)
      this.getLesson(this.studyProgress.currentLesson, index)
    } else if(this.lessonStatus) {
      this.getLesson(this.lessonStatus[0].lessonId, 0)
    }
  },
  methods: {
    getLesson: function(lessonId, index) {
      let status = this.getLessonStatus(lessonId)
      this.$refs.flickity.selectCell(index + 1)
      Store.dispatch('getLesson', lessonId)
      if(!status || status === 'locked') {
        Store.commit('set_lesson_overlay_display', true)
        return
      }
      Store.commit('set_lesson_overlay_display', false)

    },
    getLessonStatus: function(lessonId) {
      if(this.studyProgress){
        let lesson = this.lessonStatus.find( l => l.lessonId === lessonId)
        return lesson.status
      }
      return null
    },
    clearLessonContent: function(index) {
      Store.commit('set_lesson_content', null)
      Store.commit('set_lesson_overlay_display', false)
      this.$refs.flickity.selectCell(index)
    }
  },
  computed: {
    studyProgress() { return Store.getters.studyProgress },
    lessonStatus() { return Store.state.lessonStatus },
    activeLesson() {
      let lesson = Store.state.lessonContent
      if(lesson) return lesson.lessonId
      return null
    }
  },
  watch: {
    'studyProgress'(val) {
      Store.commit('set_lesson_content', null)
      Store.commit('set_lesson_overlay_display', false)
      if(val.currentLesson){
        let index = this.lessonIds.indexOf(val.currentLesson)
        this.getLesson(val.currentLesson, index)
      }
      if(val && !val.currentLesson){
        this.getLesson(this.lessonStatus[0].lessonId, 0)
      }
    }
  }
}
</script>
