import Vue from 'vue'
import Router from 'vue-router'
import Study from '@/views/Study.vue'
import LeaderDash from '@/views/LeaderDash'
import LeaderInvite from '@/views/LeaderInvite'
import NewPassword from '@/components/NewPassword'
import Login from '@/views/Login'
import Home from '@/views/Home'
import { AmplifyEventBus, AmplifyPlugin } from 'aws-amplify-vue';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { DynamoDB, CognitoIdentityServiceProvider, SES } from 'aws-sdk'
import Store from '@/store';

Vue.use(Router)
Vue.use(AmplifyPlugin, AmplifyModules);

let user;

// getUser().then((user, error) => {
//     if (user) {
//         router.push({ path: '/' })
//     }
// })


AmplifyEventBus.$on('authState', async(state) => {
    if (state === 'signedOut') {
        user = null;
        Store.commit('set_user', null);
        // Store.commit('private/setUserProgress', null);
        router.push({ path: '/auth' })
    } else if (state === 'signedIn') {
        // await Vue.prototype.$Amplify.Auth.currentSession()
        user = await getUser();
        router.push({ path: '/study' })
    }
});


AmplifyEventBus.$on('localUser', async(user) => {
    // console.log('XXXXXXXXXXXXXXXXXXXX')
    // console.log(user.challengeName)
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        Store.commit('set_user', user);
        router.push({ path: '/auth/newpassword' })
    }
});

function authUser() {
    console.log('Authing User')
    return Vue.prototype.$Amplify.Auth.currentCredentials()
        .then(credentials => {
            console.log('Got Creds from Auth')
            const tokens = Vue.prototype.$Amplify.Auth.essentialCredentials(credentials)
            const docClient = new DynamoDB.DocumentClient({ "credentials": tokens, "region": Amplify.Auth._config.region });
            const cognito = new CognitoIdentityServiceProvider({ "credentials": tokens, "region": Amplify.Auth._config.region })
            const ses = new SES({ "credentials": tokens, "region": Amplify.Auth._config.region })
            Store.commit('set_docClient', docClient)
            Store.commit('set_cognito', cognito)
            Store.commit('set_ses', ses)
            return credentials.identityId
        })
        .catch(err => { console.log('error in action', err); return err; })
}

function getUser() {
    console.log('Getting User')
    return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
            return authUser()
                .then(identityId => {
                    console.log('Done authin user in Get')
                    data.identityId = identityId
                    Store.commit('set_user', data);
                    // Store.dispatch('getStudies')
                    //   .then(() => Store.dispatch('getUserProgress') )
                    if (!data.attributes['custom:identityId'] && data.attributes['custom:role'] !== 'leader') {
                        console.log('user does not have IdentityId')
                        setUserIdentity(data)
                    }
                    return data;
                });
        }
    }).catch((e) => {
        Store.commit('set_user', null);
        return null
    });
}

async function setUserIdentity(user) {
    console.log('setting users IdentityId')
    let apiName = 'update_identity';
    let path = '/';
    let myInit = {
        headers: {},
        response: true,
        body: {
            token: user.signInUserSession.idToken.jwtToken
        }
    }
    Vue.prototype.$Amplify.API.post(apiName, path, myInit).then(response => {
        console.log('Successfully set users Identity')
        console.log(response.data)
    }).catch(error => {
        console.log('Unable to set users Identity...')
        console.log(error.response)
    });

    return

    // Vue.prototype.$Amplify.Auth.updateUserAttributes( user, {
    //   'custom:identityId': identityId
    // })
    // return
}


const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Welcome',
            component: Home,
            meta: { requiresAuth: false }
        },
        {
            path: '/study',
            name: 'Study',
            component: Study,
            meta: { requiresAuth: true }
        },
        {
            path: '/review/:sub',
            name: 'MemberReview',
            component: Study,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/dashboard',
            name: 'LeaderDash',
            component: LeaderDash,
            meta: { requiresAuth: true }
        },
        {
            path: '/invite',
            name: 'LeaderInvite',
            component: LeaderInvite,
            meta: { requiresAuth: true }
        },
        {
            path: '/auth',
            name: 'Auth',
            component: Login
        },
        {
            path: '/auth/newpassword',
            name: 'NewPassword',
            component: NewPassword,
        },
    ]
})



router.beforeResolve(async(to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        console.log('waiting for user')
        user = await getUser();
        console.log('tried to get user', user)
        if (!user) {
            return next({
                path: '/auth',
                query: {
                    redirect: to.fullPath,
                }
            });
        }
        if (to.name === "Study") {
            // console.log(to, user.attributes['custom:role'])
            if (user.attributes['custom:role'] === 'leader' || !user.attributes['custom:role'])
                return next({
                    path: '/dashboard',
                    query: {
                        redirect: to.fullPath
                    }
                })
        }
        return next()
    }



    if (to.name === "Auth" || to.name === "Welcome") {
        user = await getUser();
        if (user) return next({ path: '/study', })
        return next()
    }

    // // Restric User level accounts from Admin area
    // if (to.name.startsWith('Admin') && user.attributes["custom:role"] === 'user') {
    //     return next({ path: '/' })
    // }
    //
    // // Restric Manager level accounts from Track/Unit editing
    // if (to.matched.some(record => record.meta.requiresAdmin) && user.attributes["custom:role"] !== 'admin') {
    //     return next({ path: '/' });
    // }
    // sendGANavigationEvent(to)

    return next()
})

export default router
