
<template>
  <div class="authWrap">
    <!-- <div>{{options.header}}</div> -->
    <label class="signUpInput email">
        <input v-model="options.username" name="username" type="email" v-on:keyup.enter="signIn"  required/>
        <span class="placeholder">EMAIL ADDRESS</span>
    </label>

    <label class="signUpInput password">
        <input v-model="password" type="password" v-on:keyup.enter="signIn" required />
        <span class="placeholder">PASSWORD</span>
    </label>

    <div class="buttonWrap">
        <div class="error" v-if="error">{{ error }}</div>
        <button v-on:click="signIn">{{$Amplify.I18n.get('Sign In')}}</button>
    </div>

    <div>
          {{$Amplify.I18n.get('Forget your password? ')}}
          <a v-on:click="forgot">{{$Amplify.I18n.get('Reset password')}}</a>
        </div>
    <!-- <span v-if="options.isSignUpDisplayed">
      {{$Amplify.I18n.get('No account? ')}}
      <a v-on:click="signUp">{{$Amplify.I18n.get('Create account')}}</a>
    </span> -->
  </div>
</template>

<script>
// import Auth from '@aws-amplify/auth';
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  name: 'SignIn',
  props: ['signInConfig'],
  data () {
    return {
        password: '',
        error: '',
        logger: {},
    }
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Sign In Account'),
        username: '',
        isSignUpDisplayed: true
      }
      return Object.assign(defaults, this.signInConfig || {})
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    signIn: function(event) {
      const that = this
      this.error = ''
      if(!this.options.username) return this.setError({message: 'Username cannot be empty'})
      if(!this.password) return this.setError({message: 'Password cannont be empty'})
      this.$Amplify.Auth.signIn(this.options.username.toLowerCase(), this.password)
        .then(data => {
          this.logger.info('sign in success');
          if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'confirmSignIn')
          } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'requireNewPassword');
          } else if (data.challengeName === 'MFA_SETUP') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'setMfa');
          } else {
            return AmplifyEventBus.$emit('authState', 'signedIn')
          }
        })
        .catch((e) => {
          console.log(e)
          if (e.code && e.code === 'UserNotConfirmedException'){
            AmplifyEventBus.$emit('localUser', {username: this.options.username.toLowerCase()})
            AmplifyEventBus.$emit('authState', 'confirmSignUp')
          } else {
            this.setError(e);
          }
        });
    },
    forgot: function() {
      AmplifyEventBus.$emit('authState', 'forgotPassword')
    },
    signUp: function() {
      // AmplifyEventBus.$emit('authState', 'signUp')
      this.$router.push({name: 'Welcome'})
    },
    setError: function(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error)
    }
  }
}
</script>
