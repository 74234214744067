<template>
  <div>
    <h2>{{options.header}}</h2>
 

    <label class="signUpInput email">
        <input v-model="options.username" name="username" type="email" autocomplete="off" autocapitalize="off" v-on:keyup.enter="signIn"  required/>
        <span class="placeholder">EMAIL ADDRESS</span>
    </label>

    <label class="signUpInput code">
        <input v-model="code" name="code" type="number" v-on:keyup.enter="signIn"  required/>
        <span class="placeholder">CONFIRMATION CODE (check your email)</span>
    </label>
    <div class="help">
      {{$Amplify.I18n.get('Lost your code? ')}}
      <a v-on:click="resend">{{$Amplify.I18n.get('Resend Code')}}</a>
    </div>

  


    <div class="buttonWrap">
        <div class="error" v-if="error">{{ error }}</div>
        <button v-on:click="confirm">{{$Amplify.I18n.get('Confirm Leader Account')}}</button>
    </div>

    <span>
        {{$Amplify.I18n.get('Have an account? ')}}
        <a v-on:click="signIn">{{$Amplify.I18n.get('Back to Sign In')}}</a>
      </span>
    
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'ConfirmSignUp',
  props: ['confirmSignUpConfig'],
  data () {
    return {
        code: '',
        error: '',
        logger: {},
        amplifyUI: AmplifyUI
    }
  },
  computed: {
    options() {
      const defaults = {
        username: '',
        header: this.$Amplify.I18n.get('Confirm Sign Up'),
      }
      return Object.assign(defaults, this.confirmSignUpConfig || {})
    }
  },
  mounted: function() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
    if (!this.options.username) {
      return this.setError('Valid username not received.');
    };
  },
  methods: {
    confirm() {
        this.$Amplify.Auth.confirmSignUp(this.options.username.toLowerCase(), this.code)
          .then(() => {
            this.logger.info('confirmSignUp success')
            AmplifyEventBus.$emit('authState', 'signedOut')
          })
          .catch(e => this.setError(e));
    },
    resend() {
        this.$Amplify.Auth.resendSignUp(this.options.username.toLowerCase())
            .then(() => {
              this.logger.info('resendSignUp success')
            })
            .catch(e => this.setError(e));
    },
    signIn() {
      console.log('signin')
        AmplifyEventBus.$emit('authState', 'signedOut')
    },
    setError(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    }
  }
}
</script>
