import { render, staticRenderFns } from "./NewPassword.vue?vue&type=template&id=7a38340d&scoped=true&"
import script from "./NewPassword.vue?vue&type=script&lang=js&"
export * from "./NewPassword.vue?vue&type=script&lang=js&"
import style0 from "./NewPassword.vue?vue&type=style&index=0&id=7a38340d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a38340d",
  null
  
)

component.options.__file = "NewPassword.vue"
export default component.exports