<template>
  <div id="setupAccount">
    <div class="authHeader">
      <h1>Basic Bible Studies from the Foundry</h1>
    </div>
    <div id="auth" class="newPass">
      <h2>Choose A Password</h2>
        <label class="signUpInput password">
          <input v-model="password" type="password" placeholder="New Password" autocomplete="off" @keyDown="validatePassword" />
        </label>

        <ul class="passwordHelp">
          <li v-bind:class="{valid: hasLength}">At least 8 characters long</li>
          <li v-bind:class="{valid: hasUpper}">1 or more capital letters</li>
          <li v-bind:class="{valid: hasNumber}">1 or more numbers</li>
          <li v-bind:class="{valid: hasSpecial}">1 or more special characters (!@#$%)</li>
        </ul>

        <div class="buttonWrap">
          <button @click="setNewPassword()">Update Password &amp; Continue</button>
        </div>
    </div>
  </div>
</template>

<script>
import Store from '@/store'

export default {
  name: 'NewPassword',
  data () {
    return {
      password: '',
      hasUpper: false,
      hasSpecial: false,
      hasNumber: false,
      hasLength: false,
    }
  },
  created () {
    localStorage.clear();
  },
  destroyed () {

  },
  methods: {
    validatePassword: function() {
      this.hasUpper = false
      this.hasSpecial = false
      this.hasNumber = false
      this.hasLength = false

      const lowerCaseLetters = /[a-z]/g,
            upperCaseLetters = /[A-Z]/g,
            numbers = /[0-9]/g,
            specials = /[!@#$%^&*\[\(\)\]\-_\+\=]/g

      if(this.password.match(upperCaseLetters)) this.hasUpper = true
      if(this.password.match(specials)) this.hasSpecial = true
      if(this.password.match(numbers)) this.hasNumber = true
      if(this.password.length > 8 && this.password.length < 100) this.hasLength = true
    },
    setNewPassword() {
      let that = this;
      this.user.completeNewPasswordChallenge(this.password, this.user.challengeParam.requiredAttributes, {
        onSuccess: function(result) {
          console.log('success', result)
          that.$router.push({path: '/auth'})
          return
        },
        onFailure: function(error) {
          Store.commit('set_developerLog', 'Password Reset Failed ' + error)
          console.error('failed', error)
          return
        }
      })
    }
  },
  computed: {
    user() { return Store.state.user },
  },
  watch: {
    'password'() {
      this.validatePassword()
    }
  }
}
</script>

<style scoped>
</style>
