<template>
  <header v-bind:class="showMenu ? 'active':''">
      <h1>Basic Bible Studies</h1>
      <div class="user" v-if="user">
        <div class="pic" v-on:click="toggleMenu">
          <v-gravatar v-if="user && user.attributes" :email="user.attributes.email" :default-img="'identicon'" :size="100" />
        </div>
      </div>
      <div class="menu">
        <div class="wrapper">
          <div class="about author">
              <h4>Basic Bible Studies for New and Growing Christians</h4>
              <h5>By Chic Shaver</h5>
          </div>
          <div class="picture">
            <div class="picLarge">
              <v-gravatar v-if="user && user.attributes" :email="user.attributes.email" :default-img="'identicon'" :size="100" />
            </div>
            <div class="info">
              <h2 v-if="userFirst || userLast">{{userFirst}} {{userLast}}</h2>
              <p>User photos from Gravatar may be updated at <a href="http://gravatar.com">gravatar.com</a></p>
            </div>
          </div>
          <!--<div class="signUpInputGroup">
            <label class="signUpInput contact">
              <input type="text" disabled="disabled" required v-model="userFirst" />
              <span class="placeholder">Your First Name</span>
            </label>
            <label class="signUpInput contact">
                <input type="text" disabled="disabled" required v-model="userLast" />
                <span class="placeholder">Your Last Name</span>
            </label>
            <label class="signUpInput email">
                <input type="text" required v-model="user.attributes.email" />
                <span class="placeholder">Your Email Address</span>
            </label> -->
            <!-- <div class="smallBtn">
              <button>Update</button>
            </div> 
          </div>-->
          <div class="action">
            <button class="signout" v-on:click="signOut">Sign Out</button>
          </div>
          <div class="displayLog" v-if="logoClick > 6">
            <pre class="devLog">{{log}}</pre>
            <div class="copyAction" v-bind:class="copyActive ? 'active':''">
              <i class="fas fa-paste" @click="copyLogToClipboard"></i>
              <div class="info"></div>
            </div>
            <div class="note">Log contains your email address. It will only be used for debugging.</div>
          </div>
          <div class="about last">
            <img :src="`${publicPath}img/foundry@2x.png`" alt="" v-on:click="plusLogoClick" >
            <p>&copy; {{year}} - Foundry Publishing - <a href="https://www.thefoundrypublishing.com/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
            <p>Version: {{verNum}}</p>
          </div>
        </div>
      </div>

  </header>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import Store from '@/store'

export default {
  name: 'HeaderComponent',
  components: { },
  props: { },
  data() {
    return {
      showMenu: false,
      userFirst: null,
      userLast: null,
      year: (new Date()).getFullYear(),
      verNum: null,
      publicPath: null,
      logoClick: 0,
      copyActive: false,
    }
  },
  mounted() {
    this.setUserName(),
    this.verNum = process.env.VERSION,
    this.publicPath = process.env.BASE_URL
  },
  methods: {
    signOut: function(event) {
      this.$Amplify.Auth.signOut()
        .then(() => {
          return AmplifyEventBus.$emit('authState', 'signedOut')
          this.$ua.trackEvent('UserNav', 'Logout', 'User Elected to Logout')
        })
        .catch(err => console.log(err));
    },
    toggleMenu: function(){
      if(this.showMenu){
        this.showMenu = false;
        this.$ua.trackEvent('UserNav', 'Close', 'Hide User Info')
      } else {
        this.showMenu = true;
        this.$ua.trackEvent('UserNav', 'Open', 'Open User Info')
      }
    },
    setUserName: function(){
      if(this.user){
        this.userFirst = this.user.attributes.given_name
        this.userLast = this.user.attributes.family_name
      }
    },
    plusLogoClick: function(){
      this.logoClick = this.logoClick + 1
    },
    copyLogToClipboard: function(){
      this.copyActive = true
      const logText = document.createElement('textarea');
      logText.value = this.log;
      logText.setAttribute('readonly', '');
      logText.style.position = 'absolute';
      logText.style.left = '-9999px';
      document.body.appendChild(logText);
      logText.select();
      document.execCommand('copy');
      document.body.removeChild(logText);
      let that = this
      setInterval(function(){
        that.copyActive = false
      },500)
    }
  },
  computed: {
    user() { return Store.state.user },
    log() { return Store.state.developerLog },
  }
}
</script>
