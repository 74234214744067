<template>
  <div id="welcome">
    <img :src="`${this.publicPath}img/journey.png`" width="100%" alt="">
    <div class="sub">
      <H2><span>Welcome to</span> Basic Bible Studies for New &amp; Growing Christians</H2>
      <p>These lessons are intended to provide a foundation as you navigate the beginning of your relationship with Christ. In addition to context, community is extremely important so you’ve been paired with a Study Leader who can answer questions as they arise. It’s a good idea to meet with your leader in-person frequently. </p>
      <p>Each of the following Bible studies will contain some questions about the basics of beginning a relationship with Christ. Read the question, then look up the answer to that question in your Bible. When it says John 1:12, it means the Book of John, chapter 1, verse 12. The table of contents in the front of your Bible will list the page number for each book. While we’ve also provided links to Bible.com for easy lookup, it is good practice to learn how to look up scripture references in your Bible for times when you don’t have a link prepared for you. Read the answer from the Bible, then write it in your own words.</p>
      <p>The Bible has been translated into several versions, and the wording in each version of the Bible is a little bit different. There is a memory verse for each week that is provided in the New International Version (NIV). You should memorize the verse in the version that appeals most to you.</p>
      <p>After you complete each section, you’ll be asked to share your answers with your leader. This gives your leader important context when you meet in person and notifies them when you’re ready to proceed to the next section.</p>
    </div>
    <div class="action">
      <button class="started" @click ="unlockLesson">Get Started</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Store from '@/store'
// import HeaderComponent from '@/components/HeaderComponent.vue'
// import sectionNav from '@/components/sectionNav.vue'

export default {
  name: 'welcome',
  // components: { HeaderComponent, sectionNav },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    unlockLesson: function() {
      let that = this
      const updatedStatus = this.lessonStatus.map( p => {
        if(p.lessonId === this.lessonStatus[0].lessonId) p.status = 'inprogress'
        return p
      })

      let params = {
          TableName : `${this.ENV.tablePrefix}users`,
          Key: {'userId': this.user.identityId},
          UpdateExpression: 'set lessonStatus = :val',
          ExpressionAttributeValues: { ":val": updatedStatus }
      };

      this.docClient.update(params, function(err, data) {
          if(err) console.log(err)
          else {
            Store.commit('set_lesson_overlay_display', false)
          }
      })
    }
  },
  mounted () {
    this.$ua.trackView('Participant Welcome')
    Store.commit('set_developerLog', 'Create Log with device details')
  },
  computed: {
    user() { return Store.state.user },
    lessonStatus() { return Store.state.lessonStatus },
    docClient() { return Store.state.docClient },
    ENV() { return Store.state.ENV },
  },
}
</script>
