<template>
  <div class="dashboardItem" :class="resent ? 'reinvited':''">
    <div class="userMenu">
        <button v-if="!member.accountStatus || member.accountStatus != 'archived'" @click="archiveUser()" class="archive"></button>
        <button v-if="member.accountStatus && member.accountStatus == 'archived'" @click="activateUser()" class="unarchive"></button>
      </div>
    <div class="left">
      <div class="pic">
        <v-gravatar :email="member.email" :default-img="'identicon'" :size="100" />
      </div>
      <div class="percent">{{calcProgress()}}%</div>
    </div>
    <div class="right">
      <h4 class="personName">{{this.memberAttributes.given_name}}&nbsp;</h4>
      <p>{{member.email}}</p>
      <div v-if="cogUser && cogUser.UserStatus === 'CONFIRMED'">
        <p><span>{{isComplete()}}/{{isTotal()}}</span> Lessons Completed</p>
      </div>
      <div v-if="cogUser && cogUser.UserStatus === 'FORCE_CHANGE_PASSWORD'">
        <a href="#" @click.prevent="resendInvite(member.email)"><i class="far fa-envelope"></i> Re-send invitation</a>
      </div>
    </div>
    <div class="viewDetails" v-bind:class="cogUser && (cogUser.UserStatus === 'UNCONFIRMED' || cogUser.UserStatus === 'FORCE_CHANGE_PASSWORD') ? 'unconfirmed': ''">
      <div class="hasAction" v-if="cogUser && cogUser.UserStatus === 'CONFIRMED'" @click="getMemberResponses()">CHECK PROGRESS</div>
      <div v-else>User hasn't activated their account</div>
    </div>
  </div>
</template>

<script>
import Store from '@/store'
import Amplify, { API } from 'aws-amplify';

export default {
  name: 'LeaderDashDisciple',
  props: ['member', 'groupIndex'],
  data () {
    return {
      cogUser: null,
      memberProgress: null,
      completedLessons: null,
      resent: false,
      // memberResponses: null
    }
  },
  async mounted () {
    this.getCognitoUser()
      .then(() => {
        this.getMemberProgress()
        return
      })
      .then(() => {
        if(!this.member.id && this.memberAttributes["custom:identityId"]) this.updateUserIdentity()
      })

  },
  destroyed () {
  },
  methods: {
    unlockLesson: function(lessonId){
      const updatedStatus = this.memberProgress.map( p => {
        if(p.lessonId === lessonId) p.status = 'inprogress'
        return p
      })

      let params = {
          TableName : `${this.ENV.tablePrefix}users`,
          Key: {'userId': this.memberAttributes['custom:identityId']},
          UpdateExpression: 'set lessonStatus = :val',
          ExpressionAttributeValues: { ":val": updatedStatus }
      };

      this.docClient.update(params, function(err, data) {
          if(err) {
            console.log(err)
            Store.commit('set_developerLog', 'Error Unlocking Lesson ' + err)
          }
          else {
           console.log(data)
          }
      });
    },
    getMemberProgress: async function() {
      if(!this.memberAttributes['custom:identityId']) return
      let that = this
      let params = {
        TableName : `${this.ENV.tablePrefix}users`,
        Key: { "userId": this.memberAttributes['custom:identityId'] },
        ProjectionExpression: ['lessonStatus', 'progress']
      }

      await this.docClient.get(params, function(err, data) {
        if(err) that.memberProgress = null
        else {
          if(data.Item) {
            console.log(data.Item)
            that.memberProgress = data.Item.lessonStatus
            // that.memberResponses = data.Item.progress[that.study.studyId].lessons
            return
          }
          that.memberProgress = null
          // that.memberResponses = null
        }
      })
    },
    getMemberResponses: function() {
      this.$router.push(`/review/${this.member.sub}`)
    },
    calcProgress: function(){
      if(this.memberProgress){
       let completed = this.memberProgress.filter( p => p.status === 'complete' )
       this.completedLessons = completed
        return Math.floor(completed.length / this.memberProgress.length * 100)
      }
      return 0
    },
    isComplete: function(){
      if(this.memberProgress){
       return this.memberProgress.filter( p => p.status === 'complete' ).length
      }
      return 0
    },
    isTotal: function(){
      if(this.memberProgress){
       return this.memberProgress.length
      }
      return 0
    },
    archiveUser: function(){
      const params = {
        TableName : `${this.ENV.tablePrefix}usergroups`,
        Key: {'leaderId': this.user.identityId},
        UpdateExpression: 'set members[' + this.groupIndex + '].accountStatus = :status',
        ExpressionAttributeValues: { ":status": "archived" },
        ReturnValues: "ALL_NEW"
      }

      this.docClient.update(params, function(err, data) {
          if(err) console.log(err)
          else {
            console.log(data)
            Store.commit('set_group', data.Attributes)
          }
      });
    },
    activateUser: function(){
      const params = {
        TableName : `${this.ENV.tablePrefix}usergroups`,
        Key: {'leaderId': this.user.identityId},
        UpdateExpression: 'set members[' + this.groupIndex + '].accountStatus = :status',
        ExpressionAttributeValues: { ":status": "active" },
        ReturnValues: "ALL_NEW"
      }

      this.docClient.update(params, function(err, data) {
          if(err) {
            console.log(err)
            Store.commit('set_developerLog', 'Error Activating User ' + err)
          }
          else {
            console.log(data)
            Store.commit('set_group', data.Attributes)
          }
      });
    },
    getCognitoUser: async function(){
      let that = this
      // let getUser = new Promise(function(resolve, reject){
      //   that.cognito.adminGetUser({UserPoolId: that.ENV.userPoolId, Username: that.member.sub }, function(err, data) {
      //       if (err) reject(err)
      //       else     resolve(data)
      //     });
      // });
      //
      // await getUser.then( (data) => {
      //   that.cogUser = data
      // }, error => {
      //   that.cogUser = null
      // });
      let apiName = 'get_cognito_user';
      let path = '/';
      let myInit = {
          headers: {},
          response: true,
          body: {
            sub: that.member.sub
          }
      }

      await API.post(apiName, path, myInit).then(response => {
          console.log(response )
          that.cogUser = response.data
      }).catch(error => {
          console.log(error)
          Store.commit('set_developerLog', 'Error Getting User From Cognito ' + error)
         that.cogUser = null
      });

    },
    updateUserIdentity: function(){
      const params = {
        TableName : `${this.ENV.tablePrefix}usergroups`,
        Key: {'leaderId': this.user.identityId},
        UpdateExpression: 'set members[' + this.groupIndex + '].id = :id',
        ExpressionAttributeValues: { ":id": this.memberAttributes["custom:identityId"] },
        ReturnValues: "ALL_NEW"
      }

      if(this.memberAttributes["custom:identityId"]) {
        this.docClient.update(params, function(err, data) {
            if(err) {
              console.log(err)
              Store.commit('set_developerLog', 'Error Updating User ' + err)
            }
            else {
              Store.commit('set_group', data.Attributes)
            }
        });
      }
    },
    resendInvite: function(userEmailAddress) {
      this.resent = true;
      let params = {
        username: userEmailAddress
      }
      Store.dispatch('resendInvite', params)
        .then(() => {
          console.log(userEmailAddress + " re-invited")
        })
    },
  },
  computed: {
    user() { return Store.state.user},
    ENV() { return Store.state.ENV },
    cognito() { return Store.state.cognito },
    docClient() { return Store.state.docClient },
    study() { return Store.state.study },
    memberAttributes() {
      let attr = {}
      if(this.cogUser) this.cogUser.UserAttributes.map( a => { attr[a.Name] = a.Value })
      return attr
    }
  }
}
</script>

<style scoped>

</style>
