<template>
  <div>
    <h2>{{options.header}}</h2>
    <label class="signUpInput email">
      <input v-model="username" required autofocus />
      <span class="placeholder">{{$Amplify.I18n.get('EMAIL ADDRESS')}}</span>
    </label>
    <label class="signUpInput code" v-if="sent">
      <input v-model="code" required autofocus />
      <span class="placeholder">{{$Amplify.I18n.get('VERIFICATION CODE')}}</span>
    </label>
    <label class="signUpInput new_password" v-if="sent">
      <input v-model="password" type="password" required autofocus />
      <span class="placeholder">{{$Amplify.I18n.get('NEW PASSWORD')}}</span>
      <span class="passwordHelp">
        <strong>Requirements</strong>
        8+ Characters<br />
        1 Uppercase<br />
        1 Number<br />
        1 Special (!@#$%&amp;)
      </span>
    </label>


    <div class="buttonWrap">
      <div class="error" v-if="error">{{ error }}</div>
      <button v-if="!sent" v-on:click="submit" :disabled="!username">{{$Amplify.I18n.get('Send Code')}}</button>
      <button v-if="sent" v-on:click="verify" :disabled="!username">{{$Amplify.I18n.get('Reset Password')}}</button>
    </div>
    <span>
        <a v-if="!sent" v-on:click="signIn">{{$Amplify.I18n.get('Back to Sign In')}}</a>
        <a v-if="sent" v-on:click="submit">{{$Amplify.I18n.get('Resend Code')}}</a>
      </span>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: 'ForgotPassword',
  props: ['forgotPasswordConfig'],
  data () {
    return {
        username: '',
        code: '',
        password: '',
        error: '',
        sent: false,
        logger: {},
        amplifyUI: AmplifyUI
    }
  },
  computed: {
    options() {
      const defaults = {
        header: this.$Amplify.I18n.get('Reset your password'),
      }
      return Object.assign(defaults, this.forgotPasswordConfig || {})
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
  },
  methods: {
    submit: function() {
      this.$Amplify.Auth.forgotPassword(this.username.toLowerCase())
        .then(() => {
          this.sent = true;
          this.logger.info('forgotPassword success');
        })
        .catch(e => this.setError(e));
    },
    verify: function() {
      this.$Amplify.Auth.forgotPasswordSubmit(this.username.toLowerCase(), this.code, this.password)
        .then(() => {
          this.logger.info('forgotPasswordSubmit success');
          AmplifyEventBus.$emit('authState', 'signedOut');
        })
        .catch(e => this.setError(e));
    },
    signIn: function() {
      AmplifyEventBus.$emit('authState', 'signedOut');
    },
    setError: function(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    }
  }
}
</script>
