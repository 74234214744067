<template>
  <div class="activity">
      <div class="activityHeader" v-html="activity.question"></div>
        <label for="answer">
              <div class="pic" v-if="isLeader">
                  <v-gravatar v-if="disciple && disciple.email" :email="disciple.email" :default-img="'identicon'" :size="100" />
              </div>
            <textarea-autosize placeholder="YOUR ANSWER" rows="1" required v-bind:disabled="isLeader || lessonStatus == 'complete'" @change.native="passToParent" v-bind:value="response" :tabindex="theTabIndex+1"></textarea-autosize> 
            <!-- <span class="placeholder">Your Answer</span> -->
        </label>
  </div>
</template>

<script>
import Store from '@/store'

export default {
  name: 'LessonActivity',
  components: { },
  props: [ 'activity', 'response', 'theTabIndex', 'lessonStatus' ],
  data() {
    return {
    }
  },
  methods: {
    passToParent: function(e){
      let updatedResponse = { id: this.activity.id }
      if(e.target.value) updatedResponse.content = e.target.value
      this.$emit('responseUpdated', updatedResponse)
    }
  },
  computed: {
    isLeader() { return Store.getters.isLeader },
    disciple() { return Store.state.member }
  }
}
</script>
