var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  _vm.displayMap.showSignIn ? 'signIn' : '',
  _vm.displayMap.showSignUp ? 'signUp' : '',
  _vm.displayMap.showConfirmSignUp ? 'confirmAccount' : '',
  _vm.displayMap.showConfirmSignIn ? 'confirmSignIn' : '',
  _vm.displayMap.showForgotPassword ? 'forgot' : '',
  _vm.displayMap.requireNewPassword ? 'newPass' : '',
  _vm.displayMap.showMfa ? 'showMfa' : ''
],attrs:{"id":"auth"}},[(_vm.displayMap.showSignIn)?_c('h2',[_vm._v("Login to Basic Bible Studies")]):_vm._e(),(_vm.displayMap.showSignUp)?_c('h2',[_vm._v("Create Discipleship Leader Account")]):_vm._e(),(_vm.displayMap.showSignIn)?_c('amplify-sign-in',{attrs:{"signInConfig":_vm.options.signInConfig}}):_vm._e(),(_vm.displayMap.showSignUp)?_c('amplify-sign-up',{attrs:{"signUpConfig":_vm.options.signUpConfig}}):_vm._e(),(_vm.displayMap.showConfirmSignUp)?_c('amplify-confirm-sign-up',{attrs:{"confirmSignUpConfig":_vm.options.confirmSignUpConfig}}):_vm._e(),(_vm.displayMap.showConfirmSignIn)?_c('amplify-confirm-sign-in',{attrs:{"confirmSignInConfig":_vm.options.confirmSignInConfig}}):_vm._e(),(_vm.displayMap.showForgotPassword)?_c('amplify-forgot-password',{attrs:{"forgotPasswordConfig":_vm.options.forgotPasswordConfig}}):_vm._e(),(_vm.displayMap.requireNewPassword)?_c('amplify-require-new-password',{attrs:{"requireNewPasswordConfig":_vm.options.requireNewPasswordConfig}}):_vm._e(),(_vm.displayMap.showMfa)?_c('amplify-set-mfa',{attrs:{"mfaConfig":_vm.options.mfaConfig}}):_vm._e(),_c('p',{staticClass:"versionNumber"},[_vm._v("Version: "+_vm._s(_vm.verNum))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }