<template>
  <div id="welcomeScreen">
    <header>
      <h1>Basic Bible Studies</h1>
      <div class="haveAccount">
        <strong>Already have an account?</strong>
        <router-link tag="button" class="send" :to="{ name: 'Auth', params: {} }">Sign In</router-link>
      </div>
    </header>
    <div class="top">
      <h3>Basic Bible Studies is a one-on-one mentorship program for new and growing Christians. This program includes 8 short Bible studies to help Christians begin to study the Bible.
<a href="https://www.thefoundrypublishing.com/basic-bible-studies-for-new-and-growing-christians-app-80.html" target="_blank">Purchase Access Codes</a></h3>
    </div>
    <div class="sub">
      <div class="leftSide">
        <h2>How it works</h2>
        <h4>First</h4>
        <p>Register as a Leader by creating an account below. </p>
        <h4>Next</h4>
        <p>Add a participant by entering an access code (<a href="https://www.thefoundrypublishing.com/basic-bible-studies-for-new-and-growing-christians-app-80.html" target="_blank">available here</a>) to grant the person access to the content.</p>
        <h4>Mentor</h4>
        <p>Work with your participants to complete 8 short Bible studies.</p>
        <router-link tag="button" :to="{ path: '/auth?display=signup' }">Create a Leader Account</router-link>
      </div>
      <div class="rightSide">
        <div class="infoBlock">
          <h4>Are you a participant?</h4>
          <p><strong>If you've already been invited, to participate, your login credentials are in your welcome email.</strong> Otherwise, talk to your church, mentor, or organization to get access to the content.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Store from '@/store'

export default {
  name: 'home',
  components: { },
  computed: {
    user() { return Store.state.user },
  },
}
</script>
