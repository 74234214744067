import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import './registerServiceWorker'
import ENV from '@/config'
import Amplify from 'aws-amplify'
import { components } from 'aws-amplify-vue'
import Gravatar from 'vue-gravatar'
import VueTextareaAutosize from 'vue-textarea-autosize'
import VueAnalytics from 'vue-ua'
import IntlRelativeFormat from 'intl-relativeformat'


Vue.config.productionTip = false
Vue.component('v-gravatar', Gravatar)
Vue.use(VueTextareaAutosize)



Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: ENV.identityPoolId,
        // REQUIRED - Amazon Cognito Region
        region: ENV.region,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: ENV.userPoolId,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: ENV.userPoolWebClientId,
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: ENV.mandatorySignIn,
        // // OPTIONAL - Configuration for cookie storage
        //     cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //         domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //         path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //         expires: 365,
        //     // OPTIONAL - Cookie secure flag
        //         secure: true
        //     }
    },
    Storage: {
        bucket: ENV.bucket, //REQUIRED -  Amazon S3 bucket
        region: ENV.region, //OPTIONAL -  Amazon service region
    },
    API: {
        endpoints: [{
                name: "get_user_in_group",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/bbs-Leader-get-user-in-group/invocations",
                service: "lambda",
                region: ENV.region
            },
            {
                name: "leader_create_user",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/bbs-leader-create-user/invocations",
                service: "lambda",
                region: ENV.region
            },
            {
                name: "Code_API",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/Code_API/invocations",
                service: "lambda",
                region: ENV.region
            },
            {
                name: "update_identity",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/bbs-update-identity/invocations",
                service: "lambda",
                region: ENV.region
            },
            {
                name: "get_cognito_user",
                endpoint: "https://lambda.us-east-1.amazonaws.com/2015-03-31/functions/bbs-leader-get-cognito-user/invocations",
                service: "lambda",
                region: ENV.region
            }
        ]
    }
});

new Vue({
    router,
    store,
    render: function(h) { return h(App) },
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')


Vue.use(VueAnalytics, {
    appName: 'Basic Bible Studies',
    appVersion: process.env.VERSION,
    trackingId: 'UA-133685928-1'
        // vueRouter: router --- Not auto tracking routes to give more flexability
})

Object.defineProperty(Vue.prototype, '$relTime', { value: IntlRelativeFormat });