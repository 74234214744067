<template>
  <div id="inviteUser">
    <HeaderComponent />
    <div class="accessCodeBlock">
      <router-link tag="div" class="closeBtn" :to="{ name: 'LeaderDash', params: {} }"></router-link>
      <h2>Add a Person</h2>

      <div class="accessCode">
        <p><strong>Foundry Access Code</strong></p>
        <div class="codeWrap" v-bind:class="codeStatus">
          <input type="text" v-model="code" pattern="[A-Za-z]{6}" required placeholder="ABCDEF"/>
          <div class="action" @click="checkCodeValidity()">
            <span class="first">Check</span>
            <span class="icon"></span>
          </div>
        </div>
        <div class="result">&nbsp;{{codeIs}}</div>
      </div>

      <p><strong>Do you need a code?</strong></p>
      <p>Check with your church or <a href="https://www.thefoundrypublishing.com/basic-bible-studies-for-new-and-growing-christians-app-80.html" target="_blank">purchase a code</a> at The Foundry Publishing.</p>
    </div>
        <div class="signUpInputGroup">
          <label class="signUpInput contact">
              <input type="text" v-model="first" required />
              <span class="placeholder">FIRST NAME</span>
          </label>
          <label class="signUpInput contact">
              <input type="text" v-model="last" required />
              <span class="placeholder">LAST NAME</span>
          </label>
          <label class="signUpInput email">
              <input type="email" autocomplete="off" autocapitalize="off" v-model="email" required />
              <span class="placeholder">EMAIL ADDRESS</span>
          </label>
          <!-- <label class="signUpInput contact">
              <textarea v-model="note" required />
              <span class="placeholder">PERSONALIZED MESSAGE (OPTIONAL)</span>
          </label> -->
        </div>
    <div class="action" v-bind:class="this.working ? 'working':''">
      <button class="send" @click="addMember()">SEND WELCOME MESSAGE</button>
    </div>
    <div class="submitError">
      {{msg}}
    </div>
    <transition name="overlayFade">
      <div class="lessonOverlay" v-if="overlay">
        <div class="overlayWrap">
          <div class="locked">
            <h3>Participant Added</h3>
            <p>We've sent an email to {{this.first}} to complete the signup process. Once setup is complete, it will reflect on your dashboard</p>
          </div>
          <div class="action">
            <router-link tag="button" class="send" :to="{ name: 'LeaderDash', params: {} }">Back to Dashboard</router-link>
            <div class="link" @click="resetForm()">Add another person</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
import axios from 'axios'
import Store from '@/store'
import HeaderComponent from '@/components/HeaderComponent.vue'
import Amplify, { API } from 'aws-amplify'
import ENV from '@/config'

export default {
  name: 'LeaderInvite',
  data () {
    return {
      code: null,
      first: null,
      last: null,
      email: null,
      codeStatus: null,
      codeIs: null,
      msg: null,
      overlay: false,
      working: false,
      timer: null
      // note: null
    }
  },
  components: { HeaderComponent },
  mounted () {
    Store.dispatch('getGroup')
    ///API.configure(ENV)
  },
  destroyed () {
  },
  methods: {
    checkCodeValidity: async function() {
      let that = this
      let res;
      this.codeIs = null
      this.codeStatus = 'checking'

      if(!this.code || this.code.length < 6 || this.code.length > 6){
        this.codeIs = 'Must be 6 Letters'
        this.codeStatus = 'error'
        this.resetUi(3000);
        return
      }

      if(this.code) {
        let codeUpper = this.code.toUpperCase()
        await axios.get(`${ENV.codeApi}/iscodevalid/${codeUpper}`, {
            headers: {
              'Authorization': `Bearer ${ENV.bearer}`
            }
          }).then( response => {
            if(response.data[0].status_code === "500") {
              that.codeIs = 'Invalid Code'
              that.codeStatus = 'error'
              that.$ua.trackEvent('CheckCode', 'response', 'Invalid Code Pattern')
              res = 500
            }
            if(response.data[0].status_code === "200" && response.data[0].access_code_status === "1"){
              that.codeIs = 'Code is ready to be used'
              that.codeStatus = 'valid'
              that.$ua.trackEvent('CheckCode', 'response', 'Code Available for Use')
              res = 200
            }
            if(response.data[0].status_code === "200" && response.data[0].access_code_status === "0"){
              that.codeIs = 'Code has already been used'
              that.codeStatus = 'invalid'
              that.$ua.trackEvent('CheckCode', 'response', 'Code Already Used')
              res = 500
            }
            that.resetUi(8000);
          }, (err) => {
            that.codeIs = 'Error validating code. Please try again.'
            that.codeStatus = 'error'
            that.$ua.trackException('Error validating code ' + err + ' ' + err.response, false)
            res = 500
            Store.commit('set_developerLog', 'Error Validating Code ' + err + ' ' + err.response)
          })
          return res
      }
    },
    useCode: async function() {
      let that = this
      this.msg = null

      if(!this.code || this.code.length < 6 || this.code.length > 6){
        this.msg = 'Code is not valid'
        return { status: 500}
      }

      if(this.code) {
        let codeUpper = this.code.toUpperCase()
        let res = await axios.get(`${ENV.codeApi}/usecode/${codeUpper}`, {
            headers: {
              'Authorization': `Bearer ${ENV.bearer}`
            }
          }).then( response => {
            console.log(response)
            Store.commit('set_developerLog', 'Used Code ' + response.data[0].status_code)
            if(response.data[0].status_code === "500"){
              that.msg = 'That code has already been used'
              return { status: 500 }
            }
            if(response.data[0].status_code === "200" ) return { status: 200 }
          }, (err) => {
            console.log(err)
            that.$ua.trackException('Error Using Code ' + err, false)
            Store.commit('set_developerLog', 'Error Using Code ' + err)
            return { status: 400}
          })
          return res
      }
    },
    addMember: async function(){
      let that = this
      this.msg = null
      if(!this.code){
        this.msg = 'You must provide a valid registration code'
        return
      }

      if(!this.first){
        this.msg = 'First Name is required'
        return
      }

      if(!this.last){
        this.msg = 'Last Name is required'
        return
      }

      if(!this.email){
        this.msg = 'Email is required'
        return
      }
      this.working = true;
      let userDetails = {
        first: this.first,
        last: this.last,
        username: this.email.toLowerCase(),
        leader: this.user.attributes.email,
        leaderName: this.user.attributes.given_name
      }
      let myInit = {
          headers: {},
          response: true,
          body: {
            user: userDetails
          }
      }

        this.checkCodeValidity().then(status => {
          if(status === 200) {
              API.post('leader_create_user', '/', myInit).then(response => {
                  console.log('Lambda Create User ' + response.data.statusCode + ': ' + response.data.message)
                  if(response.data.statusCode === 400){
                    that.msg = response.data.message
                    Store.commit('set_developerLog', 'Adding Participant ' + response.data.message)
                  }
                  if(response.data.statusCode === 200){
                    Store.dispatch('addMemberToGroup', {
                      email: userDetails.username,
                      response: response.data,
                      firstName: userDetails.first,
                      fullName: `${userDetails.first} ${userDetails.last}`
                    })
                    Store.commit('set_developerLog', 'Adding Participant ' + response.data.message)
                    that.useCode().then(res => {
                      if(res.status === 400){
                        that.msg = 'Error validating code. Please try again.'
                        that.$ua.trackException('Error Adding User: Error 400', false)
                        that.working = false;
                        return
                      }

                      if(res.status === 200) {
                        that.overlay = true;
                        that.working = false;
                        that.$ua.trackEvent('Invite', 'response', 'New Participant Invited')
                        return
                      }

                      if(res.status !== 400 && res.status !== 200) {
                        that.working = false;
                        that.$ua.trackException('Error Adding User: Error ' + res.status, false)
                        return
                      }
                    })
                  }
                  return
              }).catch(error => {
                  console.log(error)
                  that.$ua.trackException('Error Creating User: Error ' + error, false)
                  Store.commit('set_developerLog', 'Error Adding Participant ' + error)
                  that.msg = 'Error creating user'
                  return
              });
          }
        })
        return
    },
    resetForm: function(){
      this.code = null;
      this.first = null;
      this.last = null;
      this.email = null;
      this.codeStatus = null;
      this.codeIs = null;
      this.msg = null;
      this.overlay = false;
      this.working = false;
    },
    resetUi: function(ms){
      let that = this;
      clearInterval(this.timer);
      this.timer = setInterval(function(){
        that.codeStatus = null;
      },ms)
    }
  },
  computed: {
    user() { return Store.state.user },
    group() { return Store.state.group },
  }
}
</script>

<style scoped>

</style>
