<template>
  <div>
      <div v-if="lessonContent.title" class="lessonContent" v-bind:class="isLeader ? 'leader' : 'notLeader'" v-cloak>
        <div class="sectionTitle" :style="getLessonHeader()">
          <h2 v-html="lessonContent.title"></h2>
          <div class="completion" v-if="lessonStatus" v-bind:class="getLessonStatus()"></div>
        </div>
        <div class="sectionIntroContent" v-html="lessonContent.content"></div>
        <div class="activities" v-bind:class="isLeader ? 'leader' : ''">
          <div class="activityGroup" v-for="(section, index) in lessonContent.sections" v-bind:key="index">
            <div class="groupHeader" v-if="section.description" v-html="section.description"></div>
              <LessonActivity
                v-for="(activity) in section.activities"
                @responseUpdated="setUpdateResponses"
                :response="getActivityResponse(lessonContent, activity)"
                :key="lessonContent.activities.find( a => a.id === activity).order"
                :activity="lessonContent.activities.find( a => a.id === activity)"
                :theTabIndex="lessonContent.activities.find( a => a.id === activity).order"
                :lessonStatus="getLessonStatus()"
                />
            </div>
          </div>
        </div>
        <div class="conclusion" v-if="lessonContent.conclusion" v-html="lessonContent.conclusion"></div>
        <div class="connect" v-bind:class="isLeader ? 'leader' : ''" v-if="!isLeader && getLessonStatus() != 'complete'">
          <div class="action" v-bind:class="this.working ? 'working':''">
            <button class="send" :tabindex="50" @click="sendResponses()">Complete &amp; send to <span v-if="leaderName">{{leaderName}}</span></button>
          </div>
        </div>
        <div class="completed" v-if="!isLeader && getLessonStatus() == 'complete'" v-cloak>
          <div class="completion"></div>
          <h4>Well Done!</h4>
          <p>You've completed this lesson and shared with <span v-if="leaderName">{{leaderName}}</span> <span v-else>your Leader</span>.</p>
        </div>
       <transition name="overlayFade"> 
      <LessonOverlay v-if="showLessonOverlay && lessonProgress" :lessonId="lessonProgress.lessonId" :lessonTitle="lessonContent.title" />
      </transition>
  </div>
</template>

<script>
import Store from '@/store'
import LessonActivity from '@/components/Lesson.Activity'
import LessonOverlay from '@/components/Lesson.Overlay'

export default {
  name: 'Lesson',
  components: { LessonActivity, LessonOverlay },
  props: [ 'lessonContent' ],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      updatedResponse: [],
      working: false
    }
  },
  methods: {
    getActivityResponse: function(lessonContent, activityId){
      let activity = lessonContent.activities.find( a => a.id === activityId)
      //let localStore = JSON.parse(localStorage.getItem(this.lessonContent.lessonId));

      if(this.getLessonStatus() === 'inprogress' && this.updatedResponse){
          let response = this.updatedResponse.find( r => r.id === activity.id)
          if(response) return response.content
      } else {
        if(this.lessonProgress && this.lessonProgress.responses){
          let response = this.lessonProgress.responses.find( r => r.id === activity.id)
          if(response) return response.content
        }
      }
      return null
    },
    getLessonStatus: function(){
      let lesson = this.lessonStatus.find( s => s.lessonId === this.lessonContent.lessonId)
      if(lesson) {
        if(lesson.status == 'complete'){
          this.working = false;
        }
        return lesson.status;
      }
      return ''
    },
    setUpdateResponses: function(response){
      this.updatedResponse = this.updatedResponse.map( r => {
        if(r.id === response.id) return response
        return r
      })

      let existingResponse = this.updatedResponse.find(r => r.id === response.id)
      if(!existingResponse) {
        this.updatedResponse.push(response)
      } else {
        existingResponse.content = response.content
      }
    },
    sendResponses: function(){
      this.working = true;
      Store.dispatch('updateUserProgress', {
        lessonId: this.lessonContent.lessonId,
        responses: this.updatedResponse
      })
      .then(() => {
        Store.dispatch('sendEmail', {
          template: 'studyCompleted',
          name: this.userName,
          lesson: this.lessonContent.title,
          sendEmail: this.user.attributes['custom:leader'],
          replyEmail: this.user.attributes.email,
          leaderName: this.user.attributes['custom:leaderName'],
          participantName: `${this.user.attributes.given_name} ${this.user.attributes.family_name}`
        });
        this.$ua.trackEvent('Study', 'Click', 'Shared ' + this.lessonContent.title + ' with leader');
        localStorage.removeItem(this.lessonContent.lessonId);
      })

    },
    getLessonHeader: function(){
      // if(this.lessonProgress && this.lessonProgress.lessonId) 
      return `background-image: url(${this.publicPath}img/lessonHeaders/${this.lessonContent.lessonId}.jpg);`;
      // return `background-image: url();`;
    }
  },
  mounted () {
    if(localStorage.getItem(this.lessonContent.lessonId)){
      this.updatedResponse = JSON.parse(localStorage.getItem(this.lessonContent.lessonId))
    }
    if(this.isLeader){
      this.$ua.trackView(this.lessonContent.title + ' as a Leader')
    } else {
      this.$ua.trackView(this.lessonContent.title + ' as a Participant')
    }
  },
  computed: {
    lessonProgress() {
      if(this.lessonContent.lessonId && Store.getters.studyProgress){
        let lesson = Store.getters.studyProgress.lessons.find( p => p.lessonId === this.lessonContent.lessonId)
        if(lesson) return lesson
      }
      return null
    },
    user() { return Store.state.user },
    showLessonOverlay() { return Store.state.showLessonOverlay },
    lessonStatus() { return Store.state.lessonStatus},
    isLeader() { return Store.getters.isLeader },
    disciple() { return Store.state.member },
    userName() { return `${Store.state.user.attributes.given_name} ${Store.state.user.attributes.family_name}` },
    leaderName() { return Store.state.user.attributes['custom:leaderName'] }
  },
  watch: {
    'lessonContent'(val) {
      this.updatedResponse = []
    },
    'lessonProgress'(val) {
      if(val && val.responses) val.responses.map(r => this.setUpdateResponses(r))
    },
    'updatedResponse'(val) {
      localStorage.setItem(this.lessonContent.lessonId, JSON.stringify(this.updatedResponse));
    }
  }
}
</script>
