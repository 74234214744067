const ENV = (function() {
    let CONFIG = {};
    const host = window.location.hostname;
    // dev:   http://localhost:8080/
    // live:  https://basicbiblestudies.com

    switch (host) {
        case 'basicbiblestudies.com':
            CONFIG.tablePrefix = 'live_bbs_';
            CONFIG.name = 'Live';
            CONFIG.identityPoolId = 'us-east-1:2854d913-5228-496e-b61e-7720cdf99939'
            CONFIG.region = 'us-east-1'
            CONFIG.userPoolId = 'us-east-1_5yfpgSFSp'
            CONFIG.userPoolWebClientId = '2pdlmfjb31qg4ng1d6fqvdjhdb'
            CONFIG.mandatorySignIn = false
            CONFIG.senderEmail = 'no-reply@basicbiblestudies.com'
                // Foundry Production
            CONFIG.codeApi = 'https://www.thefoundrypublishing.com/rest/V1/accesscodes'
            CONFIG.bearer = 'kkwns3m6y38b8sblm89ng668tx7b1kpw'
                // First Scribe Test
                // CONFIG.codeApi = 'https://thefoundrypublishing.built-for-business.com/rest/V1/accesscodes'
                // CONFIG.bearer = 'sljpdixvtfrfkrvtv4sj4is18n1ru75f'
            console.log('XXXX LIVE XXXX')
            break;
        case 'localhost':
            CONFIG.tablePrefix = 'BBS_dev_';
            CONFIG.name = 'Development';
            CONFIG.identityPoolId = 'us-east-1:af9e2789-2f82-4d07-9e86-a1c0a879a420'
            CONFIG.region = 'us-east-1'
            CONFIG.userPoolId = 'us-east-1_WmI1EhYMX'
            CONFIG.userPoolWebClientId = '151jb3q3d37vgauo6ipt3a80de'
            CONFIG.mandatorySignIn = false
            // CONFIG.bucket = 'ericbru-msu-test'
            CONFIG.senderEmail = 'bryce@discovercurious.com'
                // CONFIG.codeApi = 'https://thefoundrypublishing.built-for-business.com/rest/V1/accesscodes'
                // CONFIG.bearer = 'sljpdixvtfrfkrvtv4sj4is18n1ru75f'
            CONFIG.codeApi = 'https://www.thefoundrypublishing.com/rest/V1/accesscodes'
            CONFIG.bearer = 'kkwns3m6y38b8sblm89ng668tx7b1kpw'
            console.log('XXXX DEVELOPMENT XXXX')
            break;
        default:
            CONFIG.tablePrefix = 'BBS_dev_';
            CONFIG.name = 'Development';
            CONFIG.identityPoolId = 'us-east-1:af9e2789-2f82-4d07-9e86-a1c0a879a420'
            CONFIG.region = 'us-east-1'
            CONFIG.userPoolId = 'us-east-1_WmI1EhYMX'
            CONFIG.userPoolWebClientId = '151jb3q3d37vgauo6ipt3a80de'
            CONFIG.mandatorySignIn = false
            CONFIG.senderEmail = 'bryce@discovercurious.com'
            console.log('XXXX DEFAULT XXXX')
            break;
    }

    return CONFIG
})()

export default ENV
