<template>
  <div class="studySection" v-bind:class="showLessonOverlay ? 'showingOverlay' : ''">
    <HeaderComponent />
    <Disciple v-if="isLeader" v-cloak />
    <SectionNav v-if="(isLeader && study) || (study && availableLesson)" :lessonIds="study.lessons"/>
    <Welcome v-if="!isLeader && lessonStatuses && study && !availableLesson" v-cloak />
    <div id="pageError" v-if="!fetchingStudy && !study" v-bind:click="refreshPage()">
      <h3>Uh Oh</h3>
      <p>It seems like someone dropped their pen and stopped drawing this screen</p>
      <p><strong>Try refreshing</strong></p>
    </div>
    <div id="lessonWrap">
      <transition name="lessonFade">
        <Lesson v-if="(isLeader && study) || (study && availableLesson) && lessonContent.title" :lessonContent="lessonContent" :isLeader="isLeader" v-bind:key="lessonContent.lessonId"/>
      </transition>
    </div>
    <NextSteps v-if="isLeader && study" :allComplete="allComplete" v-cloak/>
    <div class="legal">
          The Holy Bible, New International Version&reg; (NIV&reg;). Copyright &copy; 1973, 1978, 1984, 2011 by Biblica, Inc.&trade; Used by permission of Zondervan. All rights reserved worldwide. www.zondervan.com. The "NIV" and "New International Version" are trademarks registered in the United States Patent and Trademark Office by Biblica, Inc.&trade;
        </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Store from '@/store'
import HeaderComponent from '@/components/HeaderComponent.vue'
import SectionNav from '@/components/SectionNav.vue'
import Lesson from '@/components/Lesson.vue'
import Disciple from '@/components/Study.Disciple'
import NextSteps from '@/components/Lesson.NextSteps'
import Welcome from '@/views/welcome'

export default {
  name: 'Study',
  components: { HeaderComponent, Disciple, SectionNav, Lesson, NextSteps, Welcome },
  props: ['sub'],
  data(){
      return {
        fetchingStudy: true
      }
  },
  mounted(){
    Store.dispatch('getStudies')
      .then(() => {
        this.fetchingStudy = false
        if(this.sub) Store.dispatch('getMemberProgress', this.sub)
        else Store.dispatch('getUserProgress')
      })
    Store.commit('set_developerLog', 'Create Log with device details')
  },
  methods: {
    refreshPage: function(){
      window.location.reload(true);
    }
  },
  computed: {
    lessonStatuses() { return Store.state.lessonStatus },
    availableLesson() {
      if(this.lessonStatuses) {
        let notLocked = this.lessonStatuses.filter( l => l.status != 'locked' )
        return notLocked.length
      }
      return 0
    },
    allComplete() {
      if(this.lessonStatuses) {
        let completed = this.lessonStatuses.filter( l => l.status === 'complete' )
        if(completed.length === this.lessonStatuses.length) return true
      }
      return false
    },
    study() { return Store.state.study },
    lessonContent() {
      if(Store.state.lessonContent) return Store.state.lessonContent
      return {
        title: null,
        content: '',
        lessonId: null,
        activities: [],
      }
    },
    showLessonOverlay() { return Store.state.showLessonOverlay },
    isLeader() { return Store.getters.isLeader }
  },
}
</script>
