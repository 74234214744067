<template>
    <div v-if="memberProgress" class="discipleInfo">
      <router-link tag="div" class="closeBtn" :to="{ name: 'LeaderDash', params: {} }"></router-link>
      <div class="col">
        <div class="pic">
          <v-gravatar v-if="disciple && disciple.email" :email="disciple.email" :default-img="'identicon'" :size="100" />
        </div>
        <div class="percent">{{calcProgress()}}%</div>
      </div>
      <div class="col" v-if="disciple && disciple.email">
        <h2>{{disciple.fullName}}</h2>
        <h5>{{disciple.email}}</h5>
        <h6 v-if="studyProgress">Started - {{calcNiceDate()}}</h6>
      </div>
    </div>
</template>

<script>
import Store from '@/store'
export default {
  name: 'Disciple',
  data(){
      return {
        disciplePercentComplete: null
      }
  },
  destroyed() {
    Store.commit('set_progress', null)
    Store.commit('set_member', null)
    Store.commit('set_memberId', null)
  },
  methods: {
    calcProgress: function(){
      if(this.memberProgress){
       let completed = this.memberProgress.filter( p => p.status === 'complete' )
       this.completedLessons = completed
        return Math.floor(completed.length / this.memberProgress.length * 100)
      }
      return 0
    },
    calcNiceDate: function(){
      if(global.Intl){
        var rf = new this.$relTime('en-US');
        return rf.format(this.studyProgress.started);
      } else {
        return ''
      }
    }
  },
  computed: {
    studyProgress() { return Store.getters.studyProgress },
    memberProgress() { return Store.state.lessonStatus },
    disciple() { return Store.state.member }
  },
}
</script>
