import Vue from 'vue'
import Vuex from 'vuex'
import ENV from '@/config'
import email from '@/emails'
import Amplify, { API } from 'aws-amplify'
import moment from 'moment'
import { detect } from 'detect-browser'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {

    },
    state: {
        ENV: ENV,
        user: null,
        docClient: null,
        cognito: null,
        ses: null,
        study: null,
        lessonContent: null,
        progress: null,
        memberId: null,
        member: null,
        lessonStatus: null,
        group: null,
        showLessonOverlay: false,
        version: process.env.VERSION,
        developerLog: null,
        browser: detect(),
    },
    actions: {
        getStudies: async function({ state, commit }) {
            let params = { TableName: `${state.ENV.tablePrefix}studies` }
            let getStudies = state.docClient.scan(params).promise();

            await getStudies.then((data) => {
                console.log("GOT STUDY");
                commit('set_studies', data.Items)
            }).catch((err) => {
                console.log("Error getting study")
                commit('set_studies', null)
            })
        },

        getLesson: function({ state, commit }, lessonId) {
            let params = {
                TableName: `${state.ENV.tablePrefix}lessons`,
                Key: { "lessonId": lessonId }
            }

            state.docClient.get(params, function(err, data) {
                if (err) commit('set_lesson_content', null)
                else commit('set_lesson_content', data.Item)
            })
        },


        getGroup: async function({ state, commit }) {
            let params = {
                TableName: `${state.ENV.tablePrefix}usergroups`,
                Key: { "leaderId": state.user.identityId }
            }

            let setGroups = new Promise(function(resolve, reject) {
                state.docClient.get(params, function(err, data) {
                    if (err) {
                        commit('set_group', false);
                        reject(err);
                    } else { commit('set_group', data.Item), resolve(data); }
                })
            });

            await setGroups
        },

        getUserProgress: function({ state, commit, dispatch }) {
            let params = {
                TableName: `${state.ENV.tablePrefix}users`,
                Key: { "userId": state.user.identityId }
            }

            state.docClient.get(params, function(err, data) {
                if (err) commit('set_progress', null)
                else {
                    if (data.Item) {
                        commit('set_progress', data.Item.progress)
                        commit('set_lessonStatus', data.Item.lessonStatus)
                        return
                    }
                    dispatch('setInitialProgress')
                }
            })
        },

        getMemberProgress: async function({ state, commit, dispatch }, sub) {
            if (!state.group) await dispatch('getGroup')
            let user = state.group.members.find(m => m.sub === sub)
                // let params = {
                //   TableName : `${state.ENV.tablePrefix}users`,
                //   Key: { "userId": user.id },
                //   ProjectionExpression: ['lessonStatus', 'progress']
                // }
                // commit('set_member', user)
                // commit('set_memberId', user.id)
                // await state.docClient.get(params, function(err, data) {
                //   if(err) {commit('set_progress', null), console.log(err)}
                //   else {
                //     if(data.Item) {
                //       commit('set_progress', data.Item.progress)
                //       commit('set_lessonStatus', data.Item.lessonStatus)
                //       return
                //     }
                //     dispatch('setInitialProgress')
                //   }
                // })

            let apiName = 'get_user_in_group';
            let path = '/';
            let myInit = {
                headers: {},
                response: true,
                body: {
                    sub: sub
                }
            }
            await API.post(apiName, path, myInit).then(response => {
                console.log('Got user in group')
                console.log(response.data)
                if (response.data.Item) {
                    commit('set_member', user)
                    commit('set_memberId', user.id)
                    commit('set_progress', response.data.Item.progress)
                    commit('set_lessonStatus', response.data.Item.lessonStatus)
                    return
                }
                // dispatch('setInitialProgress')
            }).catch(error => {
                console.log('Unable to get user in group')
                console.log(error.response)
                commit('set_progress', null)
            });
        },

        updateUserProgress: async function({ state, commit }, { lessonId, responses }) {
            let updatedLessonStatus = state.lessonStatus.map(s => {
                if (s.lessonId === lessonId) return { lessonId: lessonId, status: "complete" }
                return s
            })
            let updatedLessonResponses = state.progress[state.study.studyId].lessons.map(lesson => {
                if (lesson.lessonId === lessonId) return { lessonId: lessonId, responses: responses }
                return lesson
            })

            let updatedProgress = Object.assign({}, state.progress)
            updatedProgress[state.study.studyId].lessons = updatedLessonResponses
            updatedProgress[state.study.studyId].currentLesson = lessonId

            const params = {
                TableName: `${state.ENV.tablePrefix}users`,
                Key: { 'userId': state.user.identityId },
                UpdateExpression: 'set progress = :p, lessonStatus = :s',
                ExpressionAttributeValues: { ":p": updatedProgress, ":s": updatedLessonStatus },
                ReturnValues: "ALL_NEW"
            }

            await state.docClient.update(params, function(err, data) {
                if (err) console.log(err)
                else {
                    console.log(data)
                    commit('set_progress', data.Attributes.progress)
                    commit('set_lessonStatus', data.Attributes.lessonStatus)
                }
            });
        },

        setInitialProgress: async function({ state, dispatch }) {
            if (!state.study) {
                console.log('Not able to get study. Retrying...');
                await dispatch('getStudies');
            }
            const lessonsStatus = state.study.lessons.map(l => { return { "lessonId": l, "status": "locked" } })
            const lessonsResponses = state.study.lessons.map(l => { return { "lessonId": l } })
            const Item = {
                "lessonStatus": lessonsStatus,
                "progress": {
                    [state.study.studyId]: {
                        "currentLesson": null,
                        "lessons": lessonsResponses,
                        "started": Date.now(),
                        "status": "started"
                    }
                },
                "userId": state.user.identityId
            }

            let params = {
                TableName: `${state.ENV.tablePrefix}users`,
                Item: Item
            };

            state.docClient.put(params, function(err, data) {
                if (err) console.log(err)
                else {
                    console.log('Set users intial progress');
                    dispatch('getUserProgress');
                }
            });
        },

        createUser: async function({ state, commit, dispatch }, user) {
            // let params = {
            //   UserPoolId: state.ENV.userPoolId, /* required */
            //   Username: user.username, /* required */
            //   DesiredDeliveryMediums: [ "EMAIL" ],
            //   ForceAliasCreation: false,
            //   TemporaryPassword: 'Asdfasdf123!',
            //   UserAttributes: [
            //     {
            //       Name: 'given_name',
            //       Value: user.first
            //     },
            //     {
            //       Name: 'family_name',
            //       Value: user.last
            //     },
            //     {
            //       Name: 'custom:role',
            //       Value: 'user'
            //     },
            //     {
            //       Name: 'custom:leader',
            //       Value: user.leader
            //     }
            //   ]
            // }
            //
            // let createCognitoUser = new Promise(function(resolve, reject){
            //   state.cognito.adminCreateUser(params, function(error, data) {
            //     if(error) reject(error)
            //     else resolve(data)
            //   });
            // });
            //
            // await createCognitoUser.then((data) => {
            //   console.log('USER CREATED', data)
            //   dispatch('addMemberToGroup', {email: user.username, response: data, fullName: `${user.first} ${user.last}` })
            // }, error => {
            //   console.log(error)
            // });

            let apiName = 'leader_create_user';
            let path = '/';
            let myInit = {
                headers: {},
                response: true,
                body: {
                    user: user
                }
            }
            await API.post(apiName, path, myInit).then(response => {
                console.log(response)
                if (response.data.statusCode === 200) {
                    dispatch('addMemberToGroup', {
                        email: user.username,
                        response: response.data,
                        firstName: user.first,
                        fullName: `${user.first} ${user.last}`
                    })
                }
            }).catch(error => {
                console.log(error.response)
            });
        },
        resendInvite: async function({ state, commit, dispatch }, user) {
            let params = {
                UserPoolId: state.ENV.userPoolId,
                Username: user.username,
                DesiredDeliveryMediums: ["EMAIL"],
                ForceAliasCreation: false,
                MessageAction: "RESEND",
                TemporaryPassword: 'fDA6G!Wg',
            }

            let createCognitoUser = new Promise(function(resolve, reject) {
                state.cognito.adminCreateUser(params, function(error, data) {
                    if (error) reject(error)
                    else resolve(data)
                });
            });
            // await createCognitoUser.then((data) => {
            //     console.log('INVITE RESENT', data)
            // }, error => {
            //     console.log(error)
            // });
        },

        addMemberToGroup: async function({ state, commit, dispatch }, user) {
            if (!state.group) {
                dispatch('createGroup', user)
                return
            }
            let params = {
                TableName: `${state.ENV.tablePrefix}usergroups`,
                Key: { "leaderId": state.user.identityId },
                UpdateExpression: "SET #m = list_append(#m, :vals)",
                ExpressionAttributeNames: {
                    "#m": "members"
                },
                ExpressionAttributeValues: {
                    ":vals": [{ email: user.email, sub: user.response.User.Username, fullName: user.fullName }]
                },
                ReturnValues: "ALL_NEW"
            }

            let setGroups = new Promise(function(resolve, reject) {
                state.docClient.update(params, function(err, data) {
                    if (err) {
                        commit('set_group', false);
                        reject(err);
                    } else { commit('set_group', data.Item), resolve(data); }
                })
            });

            await setGroups.then(data => {
                console.log('member added', data)
                commit('set_group', data.Attributes)
            }, error => {
                console.log(error)
            })
        },

        createGroup: function({ state, dispatch }, user) {

            const Item = {
                "members": [{
                    email: user.email,
                    sub: user.response.User.Username,
                    fullName: user.fullName
                }],
                "leaderId": state.user.identityId
            }

            let params = {
                TableName: `${state.ENV.tablePrefix}usergroups`,
                Item: Item
            };

            state.docClient.put(params, function(err, data) {
                if (err) console.log(err)
                else dispatch('getGroup')
            });
        },

        sendEmail: function({ state }, options) {
            console.log(options)
            let sendTo;

            if (state.ENV.name === 'Live') sendTo = options.sendEmail
            else sendTo = 'bryce@discovercurious.com'

            let params = {
                Destination: {
                    ToAddresses: [sendTo]
                },
                Source: `${state.ENV.senderEmail}`,
                Message: email.templates[options.template](options),
                ReplyToAddresses: [`${options.replyEmail}`],
            };

            // Create the promise and SES service object
            let sendPromise = state.ses.sendEmail(params).promise();

            // Handle promise's fulfilled/rejected states
            sendPromise.then(
                function(data) {
                    console.log(data);
                }).catch(
                function(err) {
                    console.error(err, err.stack);
                });
        }
    },
    mutations: {
        set_docClient(state, docClient) {
            console.log('Setting DocClient')
            state.docClient = docClient
        },
        set_cognito(state, cognito) {
            console.log('Setting Cognito')
            state.cognito = cognito
        },
        set_ses(state, ses) {
            state.ses = ses
        },
        set_user(state, user) {
            state.user = user
        },
        set_member(state, member) {
            state.member = member
        },
        set_memberId(state, ID) {
            state.memberId = ID
        },
        set_progress(state, progress) {
            state.progress = progress
        },
        set_lessonStatus(state, status) {
            state.lessonStatus = status
        },
        set_group(state, group) {
            state.group = group
        },
        set_studies(state, studies) {
            state.study = studies[0]
        },
        set_lesson_content(state, lesson) {
            state.lessonContent = lesson
        },
        set_lesson_overlay_display(state, bool) {
            state.showLessonOverlay = bool
        },
        set_developerLog(state, msg) {
            let timeNow = moment().format('YYYY-MM-DD h:mm:ss a')
            let newLog = timeNow + ': ' + msg + '\n'
            let userRole = ''
            if (state.user.attributes['custom:role'] === 'user') {
                userRole = 'Role: Participant\n' + 'In Group: ' + state.user.attributes['custom:leader']
            } else {
                userRole = 'Role: Leader'
            }
            if (!state.developerLog) {
                state.developerLog = 'User: ' + state.user.attributes.email + '\n' +
                    'ID: ' + state.user.identityId + '\n' +
                    userRole + '\n' +
                    'Info: ' + state.browser.name + ' ' + state.browser.version + ' on ' + state.browser.os + '\n' +
                    '========================================\n' +
                    newLog
            } else {
                state.developerLog += newLog
            }
        }
    },
    getters: {
        studyProgress: state => {
            if (state.study && state.progress) return state.progress[state.study.studyId]
            return null
        },
        isLeader: state => {
            if (state.user && state.user.attributes['custom:role'] === 'user') return false
            return true
        }
    }
})

export default store