<template>
  <div id="dashboard">
    <HeaderComponent />
    <div class="dashboardHeader">
        <h3 @click="setActiveTab(0)" :class="{ active: activeTab === 0 }">Active Participants</h3>
        <h3 @click="setActiveTab(1)" :class="{ active: activeTab === 1 }">Archived</h3>
      </div>

      <div class="dashboardList" v-if="group && group.members && activeTab === 0">
        <Disciple v-for="(member, index) in group.members" v-if="!member.accountStatus || member.accountStatus != 'archived'" :member="member" :groupIndex="index" :key="member.sub" />
      </div>
      <div class="dashboardList" v-if="group && group.members && activeTab === 1">
        <Disciple v-for="(member, index) in group.members" v-if="member.accountStatus && member.accountStatus == 'archived'" :member="member" :groupIndex="index" :key="member.sub" />
      </div>

    <div class="action">
      <router-link tag="button" class="addPerson" :to="{ name: 'LeaderInvite', params: {} }">ADD A PARTICIPANT</router-link>
    </div>
    <Intro v-if="!group" v-cloak />
  </div>

</template>


<script>
import Store from '@/store'
import HeaderComponent from '@/components/HeaderComponent.vue'
import Disciple from '@/components/LeaderDash.Disciple'
import Intro from '@/components/LeaderDash.Intro'

export default {
  name: 'LeaderDash',
  data () {
    return {
      activeTab: 0
    }
  },
  components: { HeaderComponent, Disciple, Intro },
  mounted() {
    Store.dispatch('getGroup')
    this.$ua.trackView('Leader Dashboard')
    Store.commit('set_developerLog', 'Create Log with device details')
  },
  destroyed () {
  },
  methods: {
    setActiveTab: function(tab) {
      this.activeTab = tab
    },
  },
  computed: {
    user() { return Store.state.user },
    group() { return Store.state.group },
  }
}
</script>

<style scoped>

</style>
