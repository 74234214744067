<template>
  <div id="auth" v-bind:class="[
    displayMap.showSignIn ? 'signIn' : '',
    displayMap.showSignUp ? 'signUp' : '',
    displayMap.showConfirmSignUp ? 'confirmAccount' : '',
    displayMap.showConfirmSignIn ? 'confirmSignIn' : '',
    displayMap.showForgotPassword ? 'forgot' : '',
    displayMap.requireNewPassword ? 'newPass' : '',
    displayMap.showMfa ? 'showMfa' : ''
  ]">
      <h2 v-if="displayMap.showSignIn">Login to Basic Bible Studies</h2>
      <h2 v-if="displayMap.showSignUp">Create Discipleship Leader Account</h2>


      <amplify-sign-in v-if="displayMap.showSignIn" v-bind:signInConfig="options.signInConfig"></amplify-sign-in>
      <amplify-sign-up v-if="displayMap.showSignUp" v-bind:signUpConfig="options.signUpConfig"></amplify-sign-up>
      <amplify-confirm-sign-up v-if="displayMap.showConfirmSignUp" v-bind:confirmSignUpConfig="options.confirmSignUpConfig"></amplify-confirm-sign-up>
      <amplify-confirm-sign-in v-if="displayMap.showConfirmSignIn" v-bind:confirmSignInConfig="options.confirmSignInConfig"></amplify-confirm-sign-in>
      <amplify-forgot-password v-if="displayMap.showForgotPassword" v-bind:forgotPasswordConfig="options.forgotPasswordConfig"></amplify-forgot-password>
      <amplify-require-new-password v-if="displayMap.requireNewPassword" v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"></amplify-require-new-password>
      <amplify-set-mfa v-if="displayMap.showMfa" v-bind:mfaConfig="options.mfaConfig"></amplify-set-mfa>
      <p class="versionNumber">Version: {{verNum}}</p>
  </div>
</template>

<script>
import { components } from './authenticator';
import { AmplifyEventBus, AmplifyPlugin } from 'aws-amplify-vue';

export default {
  name: 'Auth',
  // props: ['authConfig'],
  data () {
    return {
        user: {
          username: null
        },
        displayMap: {},
        error: '',
        logger: {},
        verNum: null
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: {
          header: '',
          signinFields: [
            {
              label: 'Email Address',
              key: 'username',
              required: true,
              type: 'email'
            },
            {
              label: 'Password',
              key: 'password',
              required: true,
              type: 'password'
            },
          ]
        },
        signUpConfig: {
          hiddenDefaults: ['phone_number', 'email'],
          header: '',
          signUpFields: [
            {
              label: 'First Name',
              key: 'given_name',
              required: true,
              type: 'string',
              displayOrder: 1
            },
            {
              label: 'Last Name',
              key: 'family_name',
              required: true,
              type: 'string',
              displayOrder: 2
            },
            {
              label: 'Email Address',
              key: 'username',
              required: true,
              type: 'email',
              displayOrder: 3
            },
            {
              label: 'Password',
              key: 'password',
              required: true,
              type: 'password',
              displayOrder: 4,
            },
          ]
        },
        confirmSignUpConfig: {},
        confirmSignInConfig: {},
        forgotPasswordConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {}
      };

      return Object.assign(defaults, this.authConfig || {})
    }
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.options.signInConfig.username = this.user.username;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.username = this.user.username;
      this.options.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', data => {
      this.displayMap = this.updateDisplayMap(data)
    });

    if(this.$route.query.display === 'signup'){
      return this.displayMap = this.updateDisplayMap('signUp')
    }

    this.GetUser(this.$Amplify).then((val) => {
      if (val instanceof Error) {
        return this.displayMap = this.updateDisplayMap('signedOut')
      }
      this.user = val;
      return this.displayMap = this.updateDisplayMap('signedIn');
    })
    .catch(e => this.setError(e))
    this.verNum = process.env.VERSION
  },
  methods: {
    updateDisplayMap: state => {
      return {
        showSignIn: state === 'signedOut',
        showSignUp: state === 'signUp',
        showConfirmSignUp: state === 'confirmSignUp',
        showConfirmSignIn: state === 'confirmSignIn',
        showForgotPassword: state === 'forgotPassword',
        showSignOut: state === 'signedIn',
        showMfa: state === 'setMfa',
        requireNewPassword: state === 'requireNewPassword'
      }
    },
    GetUser(amplify) {
      return amplify.Auth.currentAuthenticatedUser().then((user) => {
        if (!user) {
          return null;
        }
        return user;
      }).catch(e => new Error(e));
    },
    setError: function(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error)
    }
  }
}
</script>
