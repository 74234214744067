<template>
  <div class="nextSteps">
    <div v-if="!allComplete" class="inProgress">
      <h4>Next Steps</h4>
      <p class="reminder">Remember to <strong>unlock the next session</strong>, and schedule a follow-up appointment to meet within the next week.</p>
      <p><strong>Now that they have completed this lesson,</strong> it's important to provide some encouraging comments to this participant.</p>
    </div>

      <div v-else class="allDone">
      <h4>Study Complete!</h4>
      <p class="reminder"><strong>Congratulations!</strong> Your disciple has completed all eight lessons.</p>
      <p>An important step in acknowledging this person’s step of faith is to honor him or her with a public presentation of the <strong>"Certificate of Recognition"</strong> in front of the church. This not only reinforces the person’s commitment to growing in faith, but it also is a signal to the collected body of a new member of the family.
</p>
<p>Next, encourage your disciple’s continuing growth with a group study such as "Who is this Jesus," "The Mission of Jesus," and/or another one-on-one study, such as "Basic Bible studies For the Spirit-filled and Sanctified life."</p>
    </div>
  </div>
</template>


<script>
import Store from '@/store'

export default {
  name: 'NextSteps',
  props: ['allComplete'],
  data(){
      return {}
  },
  methods: {
  },
  computed: {
  },
}
</script>
