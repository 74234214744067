<template>
    <div>
        <div class="leaderIntro">
        <h4>As a discipleship leader, here are a few important and time-tested guidelines to follow as you mentor individuals through this study:</h4>
        <p>
            <ul>
            <li>Basic Bible studies are ideal for the new Christian or professing Christian who needs to establish a devotional life. </li>
            <li>The first study should be given the new disciple shortly after conversion with the disciple&rsquo;s agreement to have it completed within 48 hours, and to meet with you face-to-face to discuss it. </li>
            <li>Show the disciple the first lesson. Go over the first question together (John 1:12) showing the disciple how to find the verse by page number in the disciple&rsquo;s Bible (or Bible that you will provide). Be sure to use a Bible version that has chapter and verse divisions.</li>
            <li>Explain the importance of your face-to-face meeting as encouragement to the disciple&rsquo;s new Christian life.</li>
            <li>Only upon completion of lesson one should you then release lesson two. Make an appointment to meet in a week. All succeeding lessons are expected weekly.</li>
            <li>If your disciple has a wrong answer, look up the correct answers together and record them. Print out the finished worksheet and return it with encouraging comments.</li>
            <li>Your face-to-face meeting should include time for your disciple to ask questions and for you to pray together.</li>
            <li>Upon completion of all studies, honor your disciples with a public presentation of the &ldquo;Certificate of Recognition” in front of the church.</li>
            <li>Congratulations! Your disciple has completed all eight lessons. Now encourage your disciple&rsquo;s continuing growth with a group study such as &ldquo;<a href="https://www.thefoundrypublishing.com/who-is-this-jesus-9780834125025.html" target="_blank" rel="noopener noreferrer">Who is this Jesus</a>,&rdquo; &ldquo;<a href="https://www.thefoundrypublishing.com/the-mission-of-jesus-9780834125032.html" target="_blank" rel="noopener noreferrer">The Mission of Jesus</a>,&rdquo; and/or another one-on-one study, such as &ldquo;<a href="https://www.thefoundrypublishing.com/basic-bible-studies-for-the-spirit-filled-and-sanctified-life-ve-91.html" target="_blank" rel="noopener noreferrer">Basic Bible Studies For the Spirit-filled and Sanctified life</a>&rdquo;.</li>
            </ul>
        </p>
        </div>
        <div class="action">
        <router-link tag="button" class="addPerson" :to="{ name: 'LeaderInvite', params: {} }">ADD A PARTICIPANT</router-link>
        </div>
    </div>
</template>

<script>

export default {
  name: 'LeaderDashIntro',
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
