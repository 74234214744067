<template>
  <div class="lessonOverlay">
    <div class="overlayWrap">
      <div class="locked">
        <h3>This study is locked.</h3>
        <p v-if="isLeader" >It’s recommended that studies are completed in order. Would you like to release it?</p>
        <p v-else >It's recommended that studies are completed in order. Please wait for your Discipleship Leader to unlock this study.</p>
      </div>
      <div class="action" v-if="isLeader">
        <button class="send" @click="unlockLesson(lessonId)">Release Study</button>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '@/store'

export default {
  name: 'LessonOverlay',
  components: { },
  props: [ 'lessonId', 'lessonTitle' ],
  data() {
    return {
    }
  },
  methods: {
    unlockLesson: function(lessonId){
      let that = this
      const updatedStatus = this.lessonStatus.map( p => {
        if(p.lessonId === lessonId) p.status = 'inprogress'
        return p
      })

      let params = {
          TableName : `${this.ENV.tablePrefix}users`,
          Key: {'userId': this.memberId},
          UpdateExpression: 'set lessonStatus = :val',
          ExpressionAttributeValues: { ":val": updatedStatus }
      };

      this.docClient.update(params, function(err, data) {
          if(err) console.log(err)
          else {
            that.$router.push('/dashboard')
            Store.commit('set_lesson_overlay_display', false)
          }
      })

      Store.dispatch('sendEmail', {
        template: 'studyUnlocked',
        lesson: this.lessonTitle,
        participantName: this.member.fullName,
        sendEmail: this.member.email,
        replyEmail: this.user.attributes.email,
        leaderName: this.user.attributes.given_name
      });
    },
  },
  computed: {
    user() { return Store.state.user },
    ENV() { return Store.state.ENV },
    showLessonOverlay() { return Store.state.showLessonOverlay },
    docClient() { return Store.state.docClient },
    lessonStatus() { return Store.state.lessonStatus },
    memberId() { return Store.state.memberId },
    member() { return Store.state.member },
    isLeader() { return Store.getters.isLeader }
  }
}
</script>

<style>


</style>
